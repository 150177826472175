import generateQuery from '@/libs/generate-query'
import HttpRequest from './HttpRequest'

class CustomerGroupProvider extends HttpRequest {
  findAll () {
    return this.get('/customer-group')
  }

  findAllGroup () {
    return this.get('/customer-group/group-customer/all')
  }

  createGroup (payload) {
    return this.post('/customer-group', payload)
  }

  updateGroup (id, payload) {
    return this.patch(`/customer-group/${id}`, payload)
  }

  deleteGroup (id) {
    return this.delete(`/customer-group/${id}`)
  }

  paginate (page = 1, limit = 10, query) {
    return this.get(`/customer-group?page=${page}&limit=${limit}${generateQuery(query)}`)
  }
}

export default CustomerGroupProvider
