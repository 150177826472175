<template>
  <b-modal
    id="modal-danger"
    v-model="modalShow"
    ok-variant="danger"
    ok-title="Accept"
    modal-class="modal-normal"
    centered>
    <div class="d-block text-center mb-1">
      <p class="font-weight-bold h2">
        Make Call
      </p>
    </div>
    <div
      class="mx-auto mb-2"
      style="width: 80%;">
    </div>
    <div>
      <TextFieldInput
        v-model="phoneNumberValue"
        label="Telephone No."
        name="telephone"
      />
    </div>
    <div>
      <SelectField
        v-model="skillId"
        :options="skillsData"
        name="Service Name"
        form-label="Service Name"
        label-option="skillName"
        return-value="skillId"
      />
    </div>
    <template #modal-footer="{ hide }">
      <div class="mx-auto">
        <b-button
          variant="primary"
          style="margin-right: 12px"
          @click="onSubmit()">
          Call
        </b-button>
        <b-button
          variant="outline-primary"
          @click="hide">
          Cancel
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import axios from 'axios'
import jwt from 'jsonwebtoken'
import TextFieldInput from '../Form/TextFieldInput2.vue'
import SelectField from '../Form/SelectField.vue'
import CxOneProvider from '@/resources/CxOneProvider'

const CxOneService = new CxOneProvider()

export default {
  components: {
    TextFieldInput,
    SelectField
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    phoneNumber: {
      type: [String, Number],
      required: false,
      default: ''
    }
  },
  data () {
    return {
      skillsData: [],
      skillId: null
    }
  },
  computed: {
    modalShow: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    phoneNumberValue: {
      get () {
        return this.phoneNumber
      },
      set (val) {
        this.$emit('update:phoneNumber', val)
      }
    },
    itemsKey () {
      return Object.keys(this.items)
    },
    itemsValue () {
      return Object.values(this.items)
    }
  },
  mounted () {
    this.getSkills()
  },
  methods: {
    async getSkills () {
      try {
        const cxoneSkill = this.$store.getters['User/cxoneSkill']
        if (cxoneSkill) {
          this.skillsData = cxoneSkill
        }
      } catch (error) {
        console.error(error)
      }
    },
    async onSubmit () {
      try {
        const token = this.$store.getters['User/cxoneToken']
        const sessionId = this.$store.getters['User/sessionId']
        const isLoginFromCXONE = this.$store.getters['User/isLoginFromCXONE']
        const { skillId } = this
        const { phoneNumber } = this
        if (token && skillId && phoneNumber && isLoginFromCXONE && sessionId) {
          const { data } = await CxOneService.dialPhone(sessionId, {
            phoneNumber,
            skillId,
            parentContactId: 0
          })
          if (data && Array.isArray(data?.agentSkillAssignments)) {
            this.skillsData = data?.agentSkillAssignments?.filter((item) => item?.mediaTypeId === 4)
          }
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.$emit('input', false)
      }
    },
    confirmAdd () {
      this.$emit('input', false)
      this.$emit('confirm', this.items)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
