/* eslint-disable import/no-cycle */
import HttpRequest from './HttpRequest'

class CxOneProvider extends HttpRequest {
  skills (id) {
    return this.get(`/cxone/skills/${id}`)
  }

  dialPhone (sessionId, payload) {
    return this.post(`/cxone/dial-phone/${sessionId}`, payload)
  }
}

export default CxOneProvider
