import generateQuery from '@/libs/generate-query'
import HttpRequest from './HttpRequest'

class CompanyCenterProvider extends HttpRequest {
  findAll () {
    return this.get('/company-center')
  }

  findAllCompanyCenter () {
    return this.get('/company-center/center/all')
  }

  createGroup (payload) {
    return this.post('/company-center', payload)
  }

  updateGroup (id, payload) {
    return this.patch(`/company-center/${id}`, payload)
  }

  deleteGroup (id) {
    return this.delete(`/company-center/${id}`)
  }

  paginate (page = 1, limit = 10, query) {
    return this.get(`/company-center?page=${page}&limit=${limit}${generateQuery(query)}`)
  }
}

export default CompanyCenterProvider
